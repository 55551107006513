import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// @ts-ignore
import loadable from "@loadable/component"
import React from "react";
import {Col, Row} from "react-bootstrap";

const NavbarComponent = loadable(() => import('./components/navigationBar/Navbar'))
const Main = loadable(() => import('./Main'))

function App() {
    const renderContent = () => {
        return (<>
                <div className="App">
                    <NavbarComponent/>
                    <Main/>
                </div>
            </>
        )
    }

    return (
        <>
            {renderContent()}
        </>)
}

export default App;
