export const TRANSLATIONS_PL= {
    Stairs: "Schody",
    StairsContent: "Specjalizacją Naszej firmy jest wykonywanie schodów  metalowych wewnętrznych jak i zewnętrznych oraz balustrad.",
    About:"O firmie",
    Contact: "Kontakt",
    StealConstruction: "Konstrukcje stalowe",
    CNC: "Panele ozdobne CNC",
    AboutContent: "Firma Pajączek zajmuje się projektowaniem, wykonywaniem oraz montażem konstrukcji stalowych.\n" +
        "Elementy konstrukcji stalowych projektujemy indywidualnie w środowisku Cad. \n" +
        "W ramach realizacji zlecń wykonujemy: cięcie, spawanie, zabezpieczenie antykorozyjnie poprzez malowanie lub ocynkowanie.\n" +
        "Jako firma rodzinna gwarantujemy profesjonalne i indywidualne podejscie do klienta.\n" +
        "Nad każdym projektem czuwa właściciel firmy Łukasz Pajączek absolwent Wyższej Szkoły Technicznej W Katowicach o kierunku budownictwo mieszkaniowe ukończonej otrzymaniem tytułu inżyniera.\n" +
        "Sprawuje on kontrolę nad produkcją oraz jakością wykonanych elementów.",
    CNCContent: "Jako, że zadowolenie klienta jest dla Nas najważniejsze oferujemy również modne oraz nowoczesne wzory paneli dekoracyjnych wypalanych technologiı CNC.\n" +
        "Wprowadzajıc panele do naszych konstrukcji sprawiamy, że sı one unikalne i niepowtarzalne.\n",
    StealConstructionContent:"Każdy projekt wykonany jest od podstaw przez naszą firmę. \n" +
        "Po konsultacji z klientem oraz wykonaniem pomiarów opracowywujemy dokumentację techniczną 2D. \n" +
        "Po zatwierdzeniu projektu przez klienta przystępujemy do wykonania oraz montażu.\n" +
        "\n" +
        "Wykonujemy:\n" +
        "\n" +
        "-Nowoczesne schody wewnętrzne i zewnętrzne, \n" +
        "-Balustrady\n" +
        "-Zadaszenia\n" +
        "-Bramy i furtki\n" +
        "-Tarasy\n" +
        "-Balkony\n" +
        "-Meble\n" +
        "-Antresole\n" +
        "\n" +
        "Wszystkie konstrukcje mogą zostać wykonane metodą tradycyjną jak i z wykorzystaniem technologii laserowej CNC. \n" +
        "\n" +
        "Do wykonania konstrukcji wykorzystujemy takie materiały jak:\n" +
        "-stal konstrukcyjna S235\n" +
        "-drewno (bukowe, dębowe)\n" +
        "-szkło VSG, ESG barwione, mleczne, bezbarwne,\n" +
        "-poliwęglan \n" +
        "\n" +
        "Możliwość zabezpieczniea konstrukcji antykorozyjnie poprzez ocynkowanie lub malowanie proszkowe.\n"
};