import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        value: []
    },
    reducers: {
        addNotification: (state, toastData) => {
            state.value.push(toastData)
        },
        closeNotification: (state, toastData) => {
            let index = state.value.indexOf(toastData, 1);
            state.value.splice(index);
        }
    }
})

// Action creators are generated for each case reducer function
export const { addNotification, closeNotification } = notificationSlice.actions

export default notificationSlice.reducer