export const TRANSLATIONS_DE = {
    Stairs: "Schody",
    StairsContent: "Specjalizacją Naszej firmy jest wykonywanie schodów  metalowych wewnętrznych jak i zewnętrznych oraz balustrad.",
    About:"Über uns",
    Contact: "Kontakt",
    StealConstruction: "Stahlkonstruktionen",
    CNC: "Platten",
    AboutContent: "Unsere Firma Spezialisiert sich auf die Herstellung sowie Montage von Stahlkonstruktionen, Geländer, Treppen, Handläufe usw. Unsere Projekte werden mit dem Programm CAD dargestellt und gezeichnet. Sie können entscheiden als Kunde welchen Korossionschutz im ihr Projekt verwendet werden. Wir bieten volgende verfahren an:\n" +
        "Verzinken sowie lackieren Pulverbeschichten.\n" +
        "Wir sind ein Familienbetrieb und garantieren  Ihnen ein Professionell und freundliches  umgehen mit unseren Kunden. Jeden Projekt wird hergestelt und gezeichnet von Lukasz Pajaczek. Herr Pajaczek hat die Technische Schule in Katowitz absolwiert im Bereich Inginer im Bau." +
        "Wir garantieren ihnen eine schöne geleiserte Stahl Blech wo nicht jeder hat.",
    CNCContent: "Für uns sind Glückliche Kunden das wichtigste. Wir bieten ihnen Moderne dekorativen Stahl Platten wo mit CNC Programm (Laser) hergestellt werden.\n" +
        "Wir garantieren ihnen eine schöne geleiserte Stahl Blech wo nicht jeder hat.",
    StealConstructionContent:"Alle Projekte werden durch unsere Firma hergestellt und gezeichnet.\n" +
        "Nachdem uns unsere Kunden kontakieren, kommen wir vorbei besprechen was ihre wünsche sind. Messen alles aus und soweit die Zeichnung fertig sind stellen wir unsere Kunden das Projekt in 2D vor und so kann der Kunde wenn gegeben nach weitere wünsche außern die wir dann noch berücksichtigen und hinzufügen.\n" +
        "Wir Produzieren :\n" +
        "-Treppen \n" +
        "-Überdachungen\n" +
        "-Terassen\n" +
        "-Möbel\n" +
        "-Tore\n" +
        "-Geländer\n" +
        "und sonstige wünsche kann man mit uns besprechen.\n" +
        "Bei uns können sie auch gelaiserte teile bestellen.\n" +
        "Unsere Sachen werden mir dem Material  S235JR (Stahl) hergestellt. Möglichkeiten mit kombination mit Holz und Glas (VSG,ESG)und weiteremöglichkeiten "
};